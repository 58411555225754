/** @format */

import React, { useContext, useEffect, useState } from "react";
// import "./App.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { AppContext } from "../../helpers/app-context";
import {
  LANDING_PAGE_DETAILS,
  ON_FORM_SUBMIT,
} from "../../helpers/api-endpoints";
import { getCurrentPositions } from "../../helpers/functions";
import CustomSelect from "../../components/CustomSelect";
import SuccessIcon from "../../assets/SuccessIcon";
const schema = yup.object().shape({
  // name: yup
  //   .string()
  //   .required("This field is required")
  //   .matches(
  //     /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/,
  //     "Please enter a valid Full Name"
  //   ),
  mobileNumber: yup
    .string()
    .trim()
    .required("This field is required")
    .matches(/^[6-9]\d{9}$/, "Please enter a valid Mobile Number"),
  // pincode: yup
  //   .string()
  //   .trim()
  //   .required("Pincode field is required")
  //   .matches(
  //     /^[1-9]{1}[0-9]{2}\s{0,1}[0-9]{3}$/,
  //     "Please enter a valid Pincode"
  //   ),
  selectProduct: yup
    .object()
    .typeError("This field is required")
    .required("This field is required"),
});

const DownloadCoupon = () => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const [products, setProducts] = useState([]);
  const ctx = useContext(AppContext);
  const getData = async () => {
    let formData = new FormData();
    formData.append("campaign_url", window.location.href);
    // formData.append("campaign_url", "https://brandlinks.omni.direct/");

    const result = await ctx.HttpPost(LANDING_PAGE_DETAILS, formData);
    if (result.status) {
      setProducts(result.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  const [showModal, setShowModal] = useState(false);
  const onSubmit = async (data) => {
    let latlongData = await getCurrentPositions();
    let formData = new FormData();
    // formData.append("name", data.name);
    formData.append("phone", data.mobileNumber);
    formData.append("sku_id", data.selectProduct?.value);
    // formData.append("campaign_url", window.location.href);
    // formData.append("pincode", data.pincode);
    formData.append("latitude", latlongData.latitude);
    formData.append("longitude", latlongData.longitude);
    formData.append("campaign_id", products?.campaign_id);
    const result = await ctx.HttpPost(ON_FORM_SUBMIT, formData);
    if (result.status) {
      downloadImage(result.data);
      setShowModal(true);
    } else {
    }
  };
  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onload = () => {
        const base64DataUrl = reader.result;
        const link = document.createElement("a");
        link.href = base64DataUrl;
        link.download = "product-coupon.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };

  return (
    <div className="mainContainer">
      {!showModal ? (
        <div className="wrapperContainer">
          <div className="formHeading">Enter your Details</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            {/* <div className="form-floating mb-4">
              <input
                {...register("name")}
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="name@example.com"
              />
              <label htmlFor="floatingInput" className="inputLabel">
                Full Name
              </label>
              <div className="errorMsg"> {errors.name?.message}</div>
            </div> */}
            <div className="input-group mb-4">
              <span className="input-group-text">+91</span>
              <div className="form-floating mobileContainer">
                <input
                  {...register("mobileNumber")}
                  type="number"
                  className="form-control mobile-number no-arrows"
                  id="mobileNumber"
                  placeholder="Mobile Number"
                />
                <label htmlFor="mobileNumber" className="inputLabel">
                  Mobile Number
                </label>
              </div>
              <div className="errorMsg">{errors.mobileNumber?.message}</div>
            </div>
            {/* <div className="form-floating mb-4">
              <input
                {...register("pincode")}
                type="number"
                className="form-control no-arrows"
                id="floatingInput"
                placeholder="505469"
              />
              <label htmlFor="floatingInput" className="inputLabel">
                Pincode
              </label>
              <div className="errorMsg"> {errors.name?.message}</div>
            </div> */}
            <CustomSelect
              control={control}
              options={products?.products?.map((items) => {
                return {
                  value: items?.variant_id,
                  label: items?.product_name,
                  image: items?.images[0]?.product_image,
                };
              })}
              name="selectProduct"
              id="selectProduct"
            />
            <div className="declarationNote">
              We'll send your Coupon via WhatsApp, making sure it reaches you
              with just a chat!
            </div>
            <button type="submit" className="btn submitBtn">
              Submit
            </button>
          </form>
        </div>
      ) : (
        <div className="modalContainer">
          <SuccessIcon />
          <div className="modalContent">
            Successfully
            <br />
            Send your Coupon via WhatsApp, on your registered mobile number
          </div>
        </div>
      )}
    </div>
  );
};

export default DownloadCoupon;
