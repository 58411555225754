/** @format */

import React from "react";
import "./App.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import LandingPage from "./Pages/stuffcool/LandingPage";
import DownloadCoupon from "./Pages/DownloadCoupon/";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <>
      <HashRouter>
        <Routes>
          <Route element={<DownloadCoupon />} path="/" />
          <Route element={<LandingPage />} path="/stuffcool" />
        </Routes>
      </HashRouter>
      <ToastContainer limit={1} />
    </>
  );
}

export default App;
