/** @format */

import { useState, createContext, useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  AuthenticationError,
  HttpHelper,
  SessionTimeoutError,
  PartialContentError,
  InternalServerError,
  DataInsufficent,
} from "./httpHelper";
import "./toast.css";
export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [baseURL, setBaseURL] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [refreshPage, setRefreshPage] = useState(false);
  const [isOnline, setIsOnline] = useState(window.navigator.onLine);
  const [deviceID, setDeviceID] = useState(null);
  const [notification_token, setNotification_token] = useState(null);
  const [appReferrer, setAppReferrer] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };

  const SetHttpContext = async () => {
    // eslint-disable-next-line no-undef
    let webURL =
      process.env.REACT_APP_API_ENDPOINT ||
      "https://magicadsadmin.omni.direct/";

    setBaseURL(webURL);
    let oContext = {
      BaseURL: webURL,
    };
    HttpHelper.SetContext(oContext);
  };

  const HttpGet = async (aFunction, aParams, aIsCritical = false) => {
    setLoading(true);
    try {
      await SetHttpContext();

      let apiRes = await HttpHelper.HttpGet(aFunction, aParams);
      if (apiRes.status) {
        setLoading(false);
        return apiRes;
      }
      if (apiRes.msg) {
        setLoading(false);
        showToastAlert({
          type: "error",
          message: apiRes.msg,
        });
      }
      return false;
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const HttpPost = async (aFunction, aPayload, aIsCritical = false) => {
    setLoading(true);
    try {
      await SetHttpContext();

      // if (aShowLoader) setShowSpinner(true);
      /*       await getAccessToken(api_type);
       */
      let apiRes = await HttpHelper.HttpPost(aFunction, aPayload);
      if (apiRes.status) {
        setLoading(false);
        return apiRes;
      }
      if (apiRes.msg) {
        setLoading(false);
        showToastAlert({
          type: "error",
          message: apiRes.msg,
        });
      }
      return false;
    } catch (e) {
      ErrorHandler(e, aIsCritical);
    } finally {
      // if (aShowLoader) setShowSpinner(false);
    }
    return false;
  };

  const ErrorHandler = (errorInfo, isCritical) => {
    // based on the type of error we will set the modal data and show the modal
    // setShowSpinner(false);
    // setIsTimeout(false);
    if (errorInfo instanceof SessionTimeoutError) {
      // setIsTimeout(true);
      // setModalData({
      //   icon: "",
      //   showModal: true,
      //   titleText: "Session Expired",
      //   messageText: errorInfo.msg,
      //   showCancelButton: false,
      //   confirmButtonText: "Okay",
      //   type: "SessionTimeout",
      // });
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof AuthenticationError) {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof InternalServerError) {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof PartialContentError) {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (errorInfo instanceof DataInsufficent) {
      showToastAlert({
        type: "error",
        message: errorInfo.messages[0],
      });
    } else if (isCritical) {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    } else if (!isOnline) {
      showToastAlert({
        type: "error",
        message: " Please check internet connection",
      });
    } else {
      showToastAlert({
        type: "error",
        message: errorInfo.message,
      });
    }
  };

  const showToastAlert = (messageInfo) => {
    if (messageInfo.type === "error") {
      toast.error(
        <div className="toastErrorMessage">{messageInfo.message}</div>,
        {
          position: "bottom-center",
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          icon: false,
          closeButton: false,
          className: "toast-msg toast-error",
        }
      );
    } else if (messageInfo.type === "info") {
      toast.info(messageInfo.message, {
        position: "bottom-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        // theme: "light",
        icon: false,
        className: "toast-msg toast-info",
      });
    } else {
      toast.success(messageInfo.message, {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        icon: false,
        closeButton: false,
        className: "toast-msg toast-success",
      });
    }
  };

  // commented by rohit kamble 19-05-2023

  // const onErrorModalButtonClick = () => {
  // 	setModalData({ ...modalData, showModal: false });
  // 	setRefreshPage(true);
  // };

  const contextValue = {
    HttpGet,
    HttpPost,

    userInfo,
    setUserInfo,
    notification_token,
    setNotification_token,
    deviceID,
    setDeviceID,

    showToastAlert,
    baseURL,
    refreshPage,
    setRefreshPage,

    isOnline,

    appReferrer,
    setAppReferrer,
    loading,
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
