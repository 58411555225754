/** @format */

import axios from "axios";
import { Capacitor } from "@capacitor/core";
import { Geolocation } from "@capacitor/geolocation";

export const getCurrentPositions = async () => {
  try {
    const position = await Geolocation?.getCurrentPosition({
      enableHighAccuracy: Capacitor?.isNative,
      maximumAge: 3000,
    });

    const latitude = position?.coords?.latitude;
    const longitude = position?.coords?.longitude;

    const permissionResult = await Geolocation?.checkPermissions();
    const coarseLocationPermissionStatus = permissionResult?.location;

    // Reverse geocoding API request
    const apiKey = "YOUR_GOOGLE_MAPS_API_KEY";
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`;
    const response = await axios.get(url);

    const address = response?.data?.results[0]?.formatted_address;
    return { latitude, longitude, coarseLocationPermissionStatus, address };
  } catch (error) {
    console.error("Error getting current position", error);
  }
};
