/** @format */

import React from "react";
import { Controller } from "react-hook-form";
import Select, { components } from "react-select";
const CustomSelect = (props) => {
  const { Option } = components;
  const CheckboxOption = (props) => {
    const { isSelected, ...restProps } = props;
    return (
      <Option sx={{ maxHeight: 200, overflowY: "auto" }} {...restProps}>
        <div style={{ display: "flex" }}>
          <img src={props?.data?.image} height={"31px"} width={"31px"} alt="" />
          <div className="selectLabel">{props?.label}</div>
        </div>
      </Option>
    );
  };
  const { options, name, control, id } = props;
  const error = control?._formState?.errors?.[id]?.message;

  const customStyles = {
    option: (base) => ({
      ...base,
      color: "#0E0750",
      background: "white",
      height: "100%",
      padding: "15px 15px 13px 15px",
      borderBottom: "1px solid #DCE4E7",
      fontSize: "14px",
      fontWeight: "500",
      "&:last-child": {
        borderBottom: "1px solid white",
      },
    }),
    ValueContainer: (base) => ({
      ...base,
      padding: "8px 0px 0px 0px",
    }),
    placeholder: (base) => ({
      ...base,
      color: "#9D9D9D",
      textAlign: "center",
      fontSize: "16px",
      margin: "0px",
      textAlign: "start",
    }),
    control: (base) => ({
      ...base,
      background: "#ffffff",
      borderRadius: "5px",
      border: "1px solid #C6C6C6",
      padding: "11px 15px 11px 15px",

      "&:focus": {
        background: "#ffffff",
        borderRadius: "5px",
        border: "1px solid #C6C6C6",
        padding: "11px 15px 11px 15px",
      },
    }),

    indicatorSeparator: () => ({
      display: "none",
    }),
  };
  return (
    <>
      <Controller
        name={name}
        control={control}
        render={({ field: { onChange, value, onBlur } }) => (
          <Select
            id={id}
            isClearable={false}
            value={value}
            onChange={onChange}
            onBlur={onBlur}
            isMulti={false}
            components={{
              Option: CheckboxOption,
            }}
            options={options}
            styles={customStyles}
            placeholder={"Select Product"}
            isSearchable={false}
          />
        )}
      />
      <div className="w-full">
        {error && <div className="errorMsg">{error}</div>}
      </div>
    </>
  );
};

export default CustomSelect;
