/** @format */

import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineClose, AiOutlineWhatsApp } from "react-icons/ai";
import { GrUpdate } from "react-icons/gr";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { getCurrentPositions } from "../../helpers/functions";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ThanksIcon from "../../assets/ThanksIcon.gif";
import SuccessIcon from "../../assets/SuccessIcon.gif";
import PopIcon from "../../assets/PopIcon.gif";
import TestImg1 from "../../assets/TestImg1.png";
import StuffcoolLogo from "../../assets/StuffcoolLogo.png";
import StarRating from "../../assets/StarRating.svg";
import fiveStars from "../../assets/Star.png";
import LocationIcon from "../../assets/LocationIcon.png";
import HeroPro from "../../assets/HeroPro.png";
import OfferLogo from "../../assets/OfferLogo.png";
import Arrow from "../../assets/Arrow.svg";
import { AppContext } from "../../helpers/app-context";
import {
  GET_NEAREST_STORE,
  LANDING_PAGE_DETAILS,
  ON_FORM_SUBMIT,
} from "../../helpers/api-endpoints";
import { FaCheckCircle } from "react-icons/fa";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "./landingPage.css";

const schema = yup.object().shape({
  mobileNumber: yup
    .string()
    .trim()
    .required("Whatsapp Number is required")
    .matches(/^[6-9]\d{9}$/, "Please enter a valid Whatsapp Number"),
});

function LandingPage() {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
    reValidateMode: "onChange",
  });
  const cookinImages = [
    {
      title: "Centurion 120 W Desktop Charger",
      imageUrl:
        "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?cs=srgb&dl=apple-iphone-smartphone-193004.jpg&fm=jpg",
    },
    {
      title: "Centurion 120 W Desktop Charger",
      imageUrl:
        "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?cs=srgb&dl=apple-iphone-smartphone-193004.jpg&fm=jpg",
    },
    {
      title: "Centurion 120 W Desktop Charger",
      imageUrl:
        "https://images.pexels.com/photos/193004/pexels-photo-193004.jpeg?cs=srgb&dl=apple-iphone-smartphone-193004.jpg&fm=jpg",
    },
  ];
  const cards = [
    {
      image: "./images/imageSnow.jpg",
      title: "Centurion 120W 4 Port Charging Station",
      otherImages: [
        {
          Image:
            "https://www.rollingstone.com/wp-content/uploads/2022/09/Apple-AirPods-Pro-2nd-gen-hero-220907.jpg?w=1581&h=1054&crop=1",
          text: "120W power",
        },
        {
          Image:
            "https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1694672652/Croma%20Assets/Entertainment/Wireless%20Earbuds/Images/301165_xzuxl0.png?tr=w-360",
          text: "50% fast charging with full power",
        },
        {
          Image:
            "https://media.wired.com/photos/6014a6587201ff6c1ba30bcb/master/pass/Gear-Apple-Airpods-Max-Angle-SOURCE-Apple.jpg",
          text: "3 x Type-C ports 1 x USB ports",
        },
        {
          Image:
            "https://cdn.vox-cdn.com/thumbor/w5fBLV3Ov82fauwTV16IuW7u5_M=/0x0:2040x1360/1400x1400/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24368803/airpodsmirror.jpg",
          text: "fast charging",
        },
      ],
      Price: "5,999",
      CutPrice: "7,999",
    },
    {
      image: "./images/imageSnow.jpg",
      title: "card 2",
      otherImages: [
        {
          Image:
            "https://www.rollingstone.com/wp-content/uploads/2022/09/Apple-AirPods-Pro-2nd-gen-hero-220907.jpg?w=1581&h=1054&crop=1",
          text: "120W power",
        },
        {
          Image:
            "https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1694672652/Croma%20Assets/Entertainment/Wireless%20Earbuds/Images/301165_xzuxl0.png?tr=w-360",
          text: "50% fast charging with full power",
        },
        {
          Image:
            "https://media.wired.com/photos/6014a6587201ff6c1ba30bcb/master/pass/Gear-Apple-Airpods-Max-Angle-SOURCE-Apple.jpg",
          text: "3 x Type-C ports 1 x USB ports",
        },
        {
          Image:
            "https://cdn.vox-cdn.com/thumbor/w5fBLV3Ov82fauwTV16IuW7u5_M=/0x0:2040x1360/1400x1400/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24368803/airpodsmirror.jpg",
          text: "fast charging",
        },
      ],
      Price: "5,999",
      CutPrice: "7,999",
    },

    {
      image: "./images/imageSnow.jpg",
      title: "card 3",
      otherImages: [
        {
          Image:
            "https://www.rollingstone.com/wp-content/uploads/2022/09/Apple-AirPods-Pro-2nd-gen-hero-220907.jpg?w=1581&h=1054&crop=1",
          text: "120W power",
        },
        {
          Image:
            "https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1694672652/Croma%20Assets/Entertainment/Wireless%20Earbuds/Images/301165_xzuxl0.png?tr=w-360",
          text: "50% fast charging with full power",
        },
        {
          Image:
            "https://media.wired.com/photos/6014a6587201ff6c1ba30bcb/master/pass/Gear-Apple-Airpods-Max-Angle-SOURCE-Apple.jpg",
          text: "3 x Type-C ports 1 x USB ports",
        },
        {
          Image:
            "https://cdn.vox-cdn.com/thumbor/w5fBLV3Ov82fauwTV16IuW7u5_M=/0x0:2040x1360/1400x1400/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24368803/airpodsmirror.jpg",
          text: "fast charging",
        },
      ],
      Price: "5,999",
      CutPrice: "7,999",
    },
    {
      image: "./images/imageSnow.jpg",
      title: "card 4",
      otherImages: [
        {
          Image:
            "https://www.rollingstone.com/wp-content/uploads/2022/09/Apple-AirPods-Pro-2nd-gen-hero-220907.jpg?w=1581&h=1054&crop=1",
          text: "120W power",
        },
        {
          Image:
            "https://media-ik.croma.com/prod/https://media.croma.com/image/upload/v1694672652/Croma%20Assets/Entertainment/Wireless%20Earbuds/Images/301165_xzuxl0.png?tr=w-360",
          text: "50% fast charging with full power",
        },
        {
          Image:
            "https://media.wired.com/photos/6014a6587201ff6c1ba30bcb/master/pass/Gear-Apple-Airpods-Max-Angle-SOURCE-Apple.jpg",
          text: "3 x Type-C ports 1 x USB ports",
        },
        {
          Image:
            "https://cdn.vox-cdn.com/thumbor/w5fBLV3Ov82fauwTV16IuW7u5_M=/0x0:2040x1360/1400x1400/filters:focal(1020x680:1021x681)/cdn.vox-cdn.com/uploads/chorus_asset/file/24368803/airpodsmirror.jpg",
          text: "fast charging",
        },
      ],
      Price: "5,999",
      CutPrice: "7,999",
    },
    // Add more cards as needed
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: true,
  };

  const testParams = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SampleNextArrow />,
  };
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "none",
        }}
        onClick={onClick}
      />
    );
  }

  const testimonials = [
    {
      image: TestImg1,
      name: "SHIKHA TANDON",
      rating: "4.7",
      message:
        "Aliquam faucibus, odio nec commodo aliquam, neque felis placerat dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed condimentum est. Mauris arcu odio, vestibulum quis dapibus",
    },
    {
      image: TestImg1,
      name: "SHIKHA TANDON",
      rating: "4.0",
      message:
        "Aliquam faucibus, odio nec commodo aliquam, neque felis placerat dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed condimentum est. Mauris arcu odio, vestibulum quis dapibus",
    },
    {
      image: TestImg1,
      name: "SHIKHA TANDON",
      rating: "4.3",
      message:
        "Aliquam faucibus, odio nec commodo aliquam, neque felis placerat dui, a porta ante lectus dapibus est. Aliquam a bibendum mi, sed condimentum est. Mauris arcu odio, vestibulum quis dapibus",
    },
  ];
  const ctx = useContext(AppContext);
  const [dealBtnClicked, setDealBtnClicked] = useState(false);
  const [products, setProducts] = useState();
  const [locAllowed, setLocAllowed] = useState(false);
  const [useIPaddress, setUseIPaddress] = useState(false);
  const [nearestStore, setNearestStore] = useState();
  const [showProducts, setShowProducts] = useState();
  const [msgSent, setMsgSent] = useState(false);
  const [agreeCheckbox, setAgreeCheckbox] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progressValue, setProgressValue] = useState("50%");

  function handleDealBtn() {
    window.scrollTo(0, 0);
    setDealBtnClicked((pre) => !pre);
  }

  const getData = async () => {
    let formData = new FormData();
    // formData.append("campaign_url", window.location.href);
    formData.append("campaign_url", "https://brandlinks.omni.direct/?1245");
    const result = await ctx.HttpPost(LANDING_PAGE_DETAILS, formData);
    if (result.status) {
      setProducts(result.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  const takeLocation = async () => {
    setLocAllowed(true);
    setLoading(true);
    let latlongData = await getCurrentPositions();
    let formData = new FormData();
    // formData.append("lat", latlongData.latitude);
    // formData.append("long", latlongData.longitude);
    formData.append("lat", "19.033049");
    formData.append("long", "73.029663");
    formData.append("campaign_id", products?.campaign_id);
    const result = await ctx.HttpPost(GET_NEAREST_STORE, formData);
    if (result.status) {
      setNearestStore(result.data);
      setLoading(false);
    }
  };
  const handleCancel = async () => {
    setUseIPaddress(true);
    const apiKey = "f7d49f0ab4d0ee";
    const res = await axios.get(`https://ipinfo.io/json?token=${apiKey}`);
    const LatLongArray = res?.data?.loc.split(",");
    let formData = new FormData();
    formData.append("lat", LatLongArray[0]);
    formData.append("long", LatLongArray[1]);
    formData.append("campaign_id", products?.campaign_id);
    const result = await ctx.HttpPost(GET_NEAREST_STORE, formData);
    if (result.status) {
      setNearestStore(result.data);
      setLoading(false);
    }
  };
  const onSubmit = async (data) => {
    setShowProducts(data?.mobileNumber);
    setProgressValue("90%");
  };
  const sendMessage = async () => {
    if (agreeCheckbox && selectedItem) {
      let latlongData = await getCurrentPositions();
      let formData = new FormData();
      formData.append("phone", showProducts);
      formData.append("sku_id", selectedItem?.variant_id);
      // formData.append("sku_id", 1);
      // formData.append("latitude", latlongData.latitude);
      // formData.append("longitude", latlongData.longitude);
      formData.append("latitude", "19.033049");
      formData.append("longitude", "73.029663");
      formData.append("campaign_id", products?.campaign_id);
      // formData.append("campaign_id", 2);
      const result = await ctx.HttpPost(ON_FORM_SUBMIT, formData);
      if (result.status) {
        setMsgSent(true);
        downloadImage(result.data);
      }
    } else if (!selectedItem) {
      ctx.showToastAlert({
        type: "error",
        message: "Please Select the Product",
      });
    } else {
      ctx.showToastAlert({
        type: "error",
        message: "Please Select the Checkbox",
      });
    }
  };
  const downloadImage = async (imageUrl) => {
    try {
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      const reader = new FileReader();
      reader.onload = () => {
        const base64DataUrl = reader.result;
        const link = document.createElement("a");
        link.href = base64DataUrl;
        link.download = "product-coupon.jpg";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

      reader.readAsDataURL(blob);
    } catch (error) {
      console.error("Error downloading image:", error);
    }
  };
  return (
    <div className="wrapper">
      {msgSent && (
        <div className="thanksPageContainer">
          <div className="thanksPageWrapper">
            <img src={ThanksIcon} alt="loading" className="gift" />
            <img src={SuccessIcon} alt="loading" className="done" />
            <div>Thanks!!</div>
            <div>
              You will receive your coupon via WhatsApp, on your registered
              mobile number
            </div>
          </div>
        </div>
      )}
      {msgSent && (
        <div className="celWrap">
          <img src={PopIcon} alt="loading" height={"100%"} width={"300px"} />
        </div>
      )}
      {(dealBtnClicked && !locAllowed && !useIPaddress) ||
      (dealBtnClicked && !locAllowed && !useIPaddress) ? (
        <div className="overlays"></div>
      ) : null}

      {dealBtnClicked && !locAllowed && !useIPaddress ? (
        <div className="locationAccess">
          <div className="close-wrap">
            <AiOutlineClose
              className="close-icon"
              onClick={(pre) => setDealBtnClicked(!pre)}
            />
          </div>

          <div className="loc-content">
            <div className="loc-heading">
              Allow your location to connect you with nearest Walk-in Store
            </div>
            <div className="loc-btns">
              <button className="allow-btn" onClick={takeLocation}>
                Allow
              </button>
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            </div>
            <p className="loc-para">
              Rest assured, we prioritize your privacy and will only use your
              location to provide tailored information
            </p>
          </div>
        </div>
      ) : null}

      <div className={msgSent ? "logo displayNone" : "logo"}>
        <img src={StuffcoolLogo} alt="logo pic" />
      </div>

      {/* hero component */}
      {locAllowed || useIPaddress ? (
        <div className={msgSent ? "store displayNone" : "store"}>
          <div
            className="progress progressContainer"
            role="progressbar"
            aria-label="Success example"
            aria-valuenow="25"
            aria-valuemin="0"
            aria-valuemax="100"
          >
            <div
              className="progress-bar"
              style={{ width: progressValue }}
            ></div>
          </div>
          {showProducts ? (
            <div className="selectProWrap">
              <div className="selectPro">
                <div className="d-flex">
                  <IoArrowBackCircleOutline
                    className="goback-Icon"
                    onClick={() => setShowProducts()}
                  />
                  <div className="selectDeal">Select Your Deal</div>
                </div>
                <div className="productList">
                  {products?.products?.map((item, index) => {
                    return (
                      <div className="mb-2">
                        <div
                          className={`${
                            selectedItem &&
                            selectedItem.product_id === item.product_id
                              ? "selectedProductCard"
                              : "bg-white"
                          } productListCard`}
                          onClick={() => setSelectedItem(item)}
                          key={index}
                        >
                          <div className="productListImage">
                            <img
                              src={item.images[0]?.product_image}
                              alt="not found"
                            />
                          </div>
                          <div className="productTitleContainer">
                            <div className="productListTitle">
                              {item.product_name}
                            </div>
                            <div className="d-flex">
                              <div className="productListPrice">
                                ₹ {item.mrp}
                              </div>
                              <div className="productListDisPrice">
                                ₹{" "}
                                {parseInt(item.discount_price) +
                                  parseInt(item.mrp)}
                              </div>
                              <div className="productListOffAmt">
                                ₹ {item.discount_price} Off
                              </div>
                            </div>
                          </div>
                          {selectedItem &&
                            selectedItem.product_id === item.product_id && (
                              <div className="FaCheckIcon">
                                <FaCheckCircle className="checkFaIcon" />
                              </div>
                            )}
                        </div>

                        {selectedItem &&
                          selectedItem?.product_id === item.product_id && (
                            <div className="selectedProduct">
                              {item.images.map((imgUrl) => {
                                return (
                                  <div>
                                    <img
                                      src={imgUrl?.product_image}
                                      alt="not found"
                                      className="selectedImg"
                                    />
                                  </div>
                                );
                              })}
                            </div>
                          )}
                      </div>
                    );
                  })}
                </div>
                <div className="agreement">
                  <input
                    type="checkbox"
                    onChange={() => setAgreeCheckbox(true)}
                  />
                  <div>
                    I Agree to receive communication messages on SMS & WhatsApp
                  </div>
                </div>
                <div className="d-flex justify-content-center">
                  <button className="productSubmitBtn" onClick={sendMessage}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="store-heading">
                Get Exclusive Discount coupon on WhatsApp
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="textbox">
                  <div className="country-code">
                    91+
                    <span className="vertical-line" />
                    <input
                      {...register("mobileNumber")}
                      placeholder="Whatsapp Number"
                      className="input"
                      type="text"
                      id="mobileNumber"
                    />
                  </div>

                  <AiOutlineWhatsApp className="wa-icon" />
                </div>
                <div className="errorMsgs">{errors.mobileNumber?.message}</div>
                <button type="submit" className="storeNextBtn">
                  Next
                </button>
              </form>
            </div>
          )}
          <hr className={msgSent ? "hr-line displayNone" : "hr-line"} />

          <div>
            {useIPaddress && showProducts && (
              <div
                className="d-flex justify-content-center"
                onClick={() => {
                  setDealBtnClicked(true);
                  setLocAllowed(false);
                  setUseIPaddress(false);
                }}
              >
                <div className="updateLocation">
                  <GrUpdate />
                  <div>Update Location</div>
                </div>
              </div>
            )}
            <div className="walkIn">Walk-in Store:</div>
            <div className="walkInContainer">
              <div className="storeImage">
                <img
                  src={nearestStore?.outlet_image}
                  alt="store"
                  width="120px"
                  height="106px"
                />
              </div>
              <div>
                <div className="storeName">{nearestStore?.outlet_name}</div>
                <div className="storeAddress">{nearestStore?.location}</div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="hero">
          <div className="discount">
            <img src={OfferLogo} alt="discount offer" />
          </div>
          <div className="heroImg">
            <img
              src={HeroPro}
              alt="airpod pic"
              height={"100%"}
              width={"100%"}
            />
          </div>
          <div className="offerTag">
            Go Beyond Limits In Style With Our Wireless Powerbank
          </div>
          <div className="offerSubtag">
            We need your location to display exclusive dealers near you. Please
            click on below button and allow location access
          </div>
        </div>
      )}

      {!dealBtnClicked && (
        <button className="floatingBtn" onClick={handleDealBtn}>
          <div className="icon">
            <div className="roundCircle">
              <img src={LocationIcon} alt="location illustration" />
            </div>
          </div>
          <div>
            <div className="icon-text">Walk-in Deals</div>
            <div className="icon-text-offer">30% Off</div>
          </div>
          <img src={Arrow} alt="arrow icon" />
        </button>
      )}
      <div className={msgSent ? "products-wrap displayNone" : "products-wrap"}>
        <h3>MORE EXCITING OFFERS</h3>
        <div className="products">
          <Slider {...settings} className="slider">
            {cards.map((card, index) => {
              return (
                <div className="card" key={index}>
                  <div className="cardImg">
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSD2-5rGUTfacv3kcNhx44DT9zjliNWWzf8bA&usqp=CAU"
                      alt="card product"
                    />
                    <div className="offBox">
                      <p>₹ 1500 OFF</p>
                    </div>
                  </div>
                  <div className="cardTitleContainer">
                    <div className="cardTitle">{card.title}</div>
                    <div className="reviews">
                      <img src={fiveStars} alt="stars" />
                      <div className="reviewTitle">Reviews</div>
                    </div>
                  </div>
                  <div className="cardOtherImg">
                    {card.otherImages.map((img, index) => {
                      return (
                        <div key={index} className="otherImgWrap">
                          <img src={img.Image} alt="other images" />
                          <div>{img.text}</div>
                        </div>
                      );
                    })}
                  </div>
                  <div className="d-flex justify-content-between">
                    <div className="prices">
                      <div className="actualPrice">₹ {card.Price}</div>
                      <div className="cutPrice">₹ {card.CutPrice}</div>
                    </div>
                    <button className="getDealBtn">Get Deal</button>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className={msgSent ? "cooking displayNone" : "cooking"}>
        <h3>Whats Cookin?</h3>
        <div className="cookinContainer">
          {cookinImages?.map((item, index) => (
            <div key={index} className="cookinImageContainer">
              <img
                src={item.imageUrl}
                alt="imageURL"
                className="cookinImages"
              />
              <div className="cookinTitle">{item.title}</div>
            </div>
          ))}
        </div>
      </div>
      <div className={msgSent ? "testimonials displayNone" : "testimonials"}>
        <h3>HERE IS WHY OUR CUSTOMER'S ADORE US</h3>
        <div className="test-wrap">
          <Slider {...testParams} className="reviewSlider">
            {testimonials.map((test, index) => {
              return (
                <div className="test-div" key={index}>
                  <div className="rating">
                    <img src={StarRating} alt="start-pic" />
                    <p>
                      <span>{test.rating}</span> / 5
                    </p>
                  </div>
                  <div className="test-profile">
                    <img src={test.image} alt="testimonial profile pic" />
                  </div>
                  <h4>{test.name}</h4>
                  <p className="test-msg">{test.message}</p>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className={msgSent ? "brands displayNone" : "brands"}>
        Brand Links
      </div>
    </div>
  );
}

export default LandingPage;
