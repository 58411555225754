/** @format */

import React from "react";

const SuccessIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
    >
      <g
        id="Group_116153"
        data-name="Group 116153"
        transform="translate(-635 -253)"
      >
        <g
          id="Ellipse_41601"
          data-name="Ellipse 41601"
          transform="translate(635 253)"
          fill="#fff"
          stroke="#4fd654"
          stroke-width="5"
        >
          <circle cx="50" cy="50" r="50" stroke="none" />
          <circle cx="50" cy="50" r="47.5" fill="none" />
        </g>
        <path
          id="Path_59263"
          data-name="Path 59263"
          d="M467,526.261l18.575,19.813L521.488,511.4"
          transform="translate(190.756 -225.737)"
          fill="none"
          stroke="#4fd654"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="5"
        />
      </g>
    </svg>
  );
};

export default SuccessIcon;
